import {
  ref, watch, onUnmounted,
  getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import assetLogStoreModule from '../assetLogStoreModule';

export default function useAssetLogList() {
  const STORE_MODULE_NAME = 'assetLog';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, assetLogStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('Tiêu đề'),
      field: 'title',
    },
    {
      label: t('Mô tả'),
      field: 'description',
    },
    {
      label: t('Thời gian'),
      field: 'time',
    },
    {
      label: t('Ghi chú'),
      field: 'note',
    },

  ];
  const rows = ref([]);
  // current selected rows
  const searchTerm = ref('');
  const provider = ref(null);
  const warehouse = ref(null);
  const assetType = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });
  const vm = getCurrentInstance().proxy;

  const fetchAssets = () => {
    store
      .dispatch('assetLog/fetchAssetLogs', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('assetLog/exportAssetLogs', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAssets();
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(provider, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.providerId = val.id;
    } else {
      delete filter.providerId;
    }
    updateParams({ filter });
  });
  watch(warehouse, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.warehouseId = val.id;
    } else {
      delete filter.warehouseId;
    }
    updateParams({ filter });
  });
  watch(assetType, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });

  //   API Call

  const refetchAssets = () => {
    fetchAssets();
  };

  return {
    columns,
    rows,
    searchTerm,
    provider,
    warehouse,
    assetType,
    apartment,
    room,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    refetchAssets,
    fetchAssets,
    exportData,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    t,
  };
}
